$hls-color-headline: rgb(0, 10, 18);
$hls-color-headline-4: rgb(0, 10, 18);

%headline-base {
  margin-top: 16px;
  color: $hls-color-headline;
  font-family: Roboto, sans-serif;
  font-weight: 700 !important;
}

%h1 {
  margin-top: 24px;

  font-size: 22px;

  @extend %headline-base;
}

%h2 {

  font-size: 18px;

  @extend %headline-base;
}

%h3 {

  font-size: 14px;

  @extend %headline-base;
}

%h4 {

  color: $hls-color-headline-4;
  font-size: 12px;

  @extend %headline-base;
}
@import "colors";

$hls-color-foreground: rgb(0, 10, 18);

%typeset-base {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.3;
}

@import "typeset/text-base";

@import "typeset/headline";

@import "typeset/link";


%text-base-regular {

  color: $hls-color-foreground;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 18px;

  @extend %typeset-base;
}

%text-base-sm {

  font-size: 12px;
  line-height: 16px;

  @extend %text-base-regular;
}

%text-base-xs {

  font-size: 10px;
  line-height: 13px;

  @extend %text-base-regular;
}

%text-base-muted {

  color: rgb(128, 128, 128);

  @extend %text-base-regular;
}

%text-base-error {

  color: rgb(214, 51, 57);

  @extend %text-base-regular;
}

%text-base-warning {

  color: #fa9600;

  @extend %text-base-regular;
}

%text-base-success {

  color: #64be28;

  @extend %text-base-regular;
}
.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.underline {
  font-style: italic;
}

.color-error {
  @extend %text-base-error;
}

.color-warning {
  @extend %text-base-warning;
}

.color-success {
  @extend %text-base-success;
}

.color-muted {
  @extend %text-base-muted;
}

.text-sm {
  @extend %text-base-sm;
}

.text-xs {
  @extend %text-base-xs;
}

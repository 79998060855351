@import "../config";

@import "typeset/headline";

@import "typeset/link";

@import "typeset/typeset-base";

body {
  background-color: $hls-color-app-background;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
select,
button {
  font-feature-settings: "kern", "liga";

  @extend %typeset-base;

  @extend %text-base-regular;
}

p {
  margin-bottom: 1.6rem;
}
$hls-link-color: #005aa0;
$hls-link-color--hover: #2277BC;

%link-base {
  color: $hls-link-color;
  text-decoration: none;

  cursor: pointer;

  @extend %text-base-regular;
}

%link-base--hover {
  color: $hls-link-color--hover;
  text-decoration: underline;
}
